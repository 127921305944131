import { Grid, TextField } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import {
  useGetOne,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NullableBooleanInput,
  SelectInput,
  DateTimeInput,
  DateField,
  Labeled,
  NumberField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput,
} from "react-admin";
import { getPointsGroupedDeliveries } from "./utils";
import { StatsCard } from "./components/StatsCard";
import { useStore } from "./store";
import { Delivery, DeliveryType, Subject } from "../shared/types";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import "leaflet-area-select";
import { useOptions } from "../shared/hooks/useOptions";
import { initField } from "../shared/utils";
import { CustomList } from "../components/CustomList";
import { RoutePlannerMap } from "./components/RoutePlannerMap";
import { Form } from "react-final-form";
import { toInteger } from "lodash";

export const RoutePlannerPage = () => {
  const { data: optionsDelivery } = useOptions("deliveries", "GET");

  const filters = optionsDelivery
    ? [
      <ReferenceArrayInput
        helperText={false}
        fullWidth
        source="province"
        reference="autocomplete/province"
        label="Provincia"
      >
        <AutocompleteArrayInput optionText="label" resettable />
      </ReferenceArrayInput>,
      <ReferenceInputSubject
        allowEmpty
        fullWidth
        source="store"
        filter={{ type: "default_store" }}
        label="Magazzino"
        helperText={false}
      />,
      <ReferenceArrayInput
        helperText={false}
        fullWidth
        source="receivers"
        reference="autocomplete/subject"
        label="Destinatari"
      >
        <AutocompleteArrayInput optionText="label" resettable />
      </ReferenceArrayInput>,
      <ReferenceArrayInput
        helperText={false}
        fullWidth
        source="clients"
        filter={{ type: "client" }}
        reference="autocomplete/subject"
        label="Clienti"
      >
        <AutocompleteArrayInput optionText="label" resettable />
      </ReferenceArrayInput>,
      <ReferenceInput
        helperText={false}
        fullWidth
        source="imported_file"
        reference="autocomplete/imported-files"
        label="File"
      >
        <AutocompleteInput optionText="label" resettable />
      </ReferenceInput>,
      <ReferenceArrayInput
        source="region"
        label="Regioni"
        reference="autocomplete/region"
        className="filter-field-small"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,
      <ReferenceArrayInput
        helperText={false}
        fullWidth
        source="zones"
        filter={{ type: "zones" }}
        reference="autocomplete/delivery-zones"
        label="Zone"
      >
        <AutocompleteArrayInput optionText="label" resettable />
      </ReferenceArrayInput>,
      <NullableBooleanInput
        fullWidth
        source="to_be_tractioned"
        label="Da trazionare"
        alwaysOn
      />,
      <DateTimeInput
        fullWidth
        source="delivery_date_before"
        label="Data missione fino al"
      />,
      <SelectArrayInput
        {...initField("delivery_types", optionsDelivery, "array")}
        fullWidth
        allowEmpty
        choices={optionsDelivery.delivery_type.choices}
        label="Tipi missioni"
      />,
      <NullableBooleanInput
        source="note"
        label="Contiene bolle"
      />,
      <ReferenceArrayInput
        reference="autocomplete/category"
        defaultValue={[]}
        source={"categories"}
        label="Categorie"
        sort={{ "field": "label", "order": "ASC" }}
        className="filter-field-small">
        <AutocompleteArrayInput optionText="label" fullWidth />
      </ReferenceArrayInput>,
    ]
    : [];
  const {
    addedSegments,
    vehicle,
    groupedDeliveries,
    finalPointId,
    startPointId,
    startDate,
    warehouseAlert,
    updateStartDate,
  } = useStore();
  const [totKm, setTotKm] = useState(0);
  const [totDuration, setTotDuration] = useState(0);

  const count_list_destination = groupedDeliveries.reduce(
    (seconds, deliveryGroup) => {
      if (
        (!deliveryGroup.isPickUpPoint && deliveryGroup.delivery_type !== DeliveryType.WITHDRAW) ||
        (deliveryGroup.isPickUpPoint && deliveryGroup.delivery_type === DeliveryType.WITHDRAW)
      ) seconds += 1;
      return seconds;
    },
    0
  )

  const mainStats = useMemo(() => {
    return addedSegments
      .reduce((deliveries, segment) => {
        if (
          !deliveries.find((delivery) => delivery.id === segment.delivery.id)
        ) {
          deliveries.push(segment.delivery);
        }
        return deliveries;
      }, [] as Delivery[])
      .reduce(
        (previous, current) => {
          return {
            leftWeight: previous.leftWeight - current.weight!,
            maxWeight: previous.maxWeight,
            currentWeight: previous.currentWeight + (current.delivery_type === DeliveryType.WITHDRAW ? 0 : current.weight!),
            showWeightAlert: (
              ((previous.leftWeight - current.weight! < 0) && previous.maxWeight !== 0) ||
              (previous.maxPackages - (previous.totalPackages + current.packages_number!) < 0 && previous.maxPackages !== 0)
            )
              ? true : false,
            totalPackages: previous.totalPackages + current.packages_number!,
            maxPackages: previous.maxPackages,
            currentPackages:
              previous.currentPackages + (current.delivery_type === DeliveryType.WITHDRAW ? 0 : current.packages_number!),
            n_destination: count_list_destination
          };
        },
        {
          leftWeight: vehicle ? vehicle.max_weigth : 0,
          maxWeight: vehicle ? vehicle.max_weigth : 0,
          currentWeight: 0,
          showWeightAlert: false,
          totalPackages: 0,
          maxPackages: vehicle ? vehicle.max_items : 0,
          currentPackages: 0,
          n_destination: 0,
        }
      );
  }, [addedSegments, vehicle]);

  const { data: finalPointSubject } = useGetOne("subjects", finalPointId!, {
    enabled: !!finalPointId,
  });
  const { data: startPointSubject } = useGetOne("subjects", startPointId!, {
    enabled: !!startPointId,
  });
  const points = useMemo(() => {
    const points = getPointsGroupedDeliveries(
      groupedDeliveries,
      startPointSubject as Subject,
      finalPointSubject as Subject
    );
    return points;
  }, [groupedDeliveries, finalPointSubject, startPointSubject]);
  const endDate = useMemo(() => {
    const additionalUnloadTime = groupedDeliveries.reduce(
      (seconds, deliveryGroup) => {
        if (!deliveryGroup.isPickUpPoint) seconds += 180;
        return seconds;
      },
      0
    );
    const newDate = new Date(startDate);
    newDate.setSeconds(
      newDate.getSeconds() + totDuration + additionalUnloadTime
    );
    return newDate;
  }, [groupedDeliveries, startDate, totDuration]);
  const duration = useMemo(() => {
    const additionalUnloadTime = groupedDeliveries.reduce(
      (seconds, deliveryGroup) => {
        if (!deliveryGroup.isPickUpPoint) seconds += 180;
        return seconds;
      },
      0
    );
    let minutes = (totDuration + additionalUnloadTime) / 60.0
    let zero_h = toInteger(minutes / 60) >= 10 ? "" : "0"
    let zero_m = toInteger(minutes % 60) >= 10 ? "" : "0"
    return `${zero_h}${toInteger(minutes / 60)}:${zero_m}${toInteger(minutes % 60)}`; // HOUR: MINUTES

  }, [groupedDeliveries, totDuration]);

  useEffect(() => {
    if (points.length <= 0) {
      setTotKm(0);
      setTotDuration(0);
    }
  }, [points]);

  const onRoutePointsFound = (totalDistance: number, totalDuration: number) => {
    setTotKm(totalDistance);
    setTotDuration(totalDuration);
  };
  return (
    <CustomList
      filters={filters}
      filter={{ map_filter: true }}
      resource="segments"
      basePath="/planner"
      syncWithLocation={true}
      filterDefaultValues={{ to_be_tractioned: false, route_id: [] }}
      pagination={<></>}
      title={
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={6}>
            <StatsCard {...mainStats} totalKm={totKm} />
          </Grid>
          <Form
            initialValues={{
              start_date: startDate,
            }}
            onSubmit={() => { }}
            render={() => (
              <Grid item container xs alignItems="center">
                <Grid item xs={12} md={7} lg={5}>
                  <DateTimeInput
                    label="Ora inizio"
                    onChange={(date) => {
                      updateStartDate(new Date(date.target.value));
                    }}
                    isRequired={true}
                    source={"start_date"}
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={5}>
                  <Labeled label={"Ora fine"}>
                    <DateField
                      options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }}
                      record={{
                        id: endDate.toString(),
                        date: endDate ? endDate : null,
                      }}
                      showTime
                      label="Ora fine"
                      source={"date"}
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={1} lg={2}>
                  <Labeled label={"Durata"}>
                    <TextField
                      value={duration ? (duration) : "0: 0"}
                      disabled={true}
                    >
                    </TextField>
                  </Labeled>

                </Grid>
              </Grid>
            )}
          />
        </Grid>
      }
      shouldExport={false}
    >
      <RoutePlannerMap
        mainStats={mainStats}
        points={points}
        filters={filters}
        onRoutePointsFound={onRoutePointsFound}
        totKm={totKm}
      />
    </CustomList>
  );
};
