import { Card, CardContent, CardHeader, Checkbox, Grid, makeStyles, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { getUser } from "../../authProvider";


import { useEffect, useState, useCallback, useRef, useMemo } from "react";

import dataprovider, { client } from "../../dataProvider";
import { AutocompleteArrayInput, Button, DateField, DateTimeInput, FunctionField, RecordContext, ReferenceArrayField, ReferenceArrayInput, Tab, useNotify, useRedirect, Record } from "react-admin";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { da } from "date-fns/locale";
import { IdentifierField } from "../../components/IdentifierField";
import { initField } from "../../shared/utils";
import { useOptions } from "../../shared/hooks/useOptions";
import { EmployeeField } from "../../components/EmployeeField";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { StatCard } from "../../components/StatCard";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { set } from "lodash";


const useStyles = makeStyles({
  root: {
    "& input": {
      height: "30px",
    },
    "& th": {
      borderRight: "0px solid lightgray",
      backgroundColor: "#fddfdd",
      padding: "10px 0px",
      fontSize: "1.2em",
      textAlign: "center",
    },
    "& td": {
      borderRight: "0px solid lightgray",
      padding: "4px 0px",
    },
    "& tr": {

      '&:hover': {
        backgroundColor: '#d1dfd1 !important' // fifth bg color
      }
    },

  }
});

export const PresenceDayList = (props: any) => {
  const subRowHeight = 30;
  const classes = useStyles();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlStartDate = urlParams.get('urlStartDate') ? urlParams.get('startDate') : new Date().toISOString().substring(0, 16);
  const urlEndDate = urlParams.get('endDate') ? urlParams.get('endDate') : new Date().toISOString().substring(0, 16);
  const urlEmployee = urlParams.get('employees') ? urlParams.get('employees') : null;

  const [loadingEmplyees, setLoadingEmplyees] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const notify = useNotify();
  const [presencesDays, setPresencesDays] = useState<any>([]);
  const [presencesData, setPresencesData] = useState<any>({});
  const [selectedData, setSelectedData] = useState<any>([]);
  const [stats, setStats] = useState<any>({
    sum_presences: 0,
    sum_minutes: 0,
    sel_sum_presences: 0,
    sel_sum_minutes: 0,
  });
  const [updateStats, setUpdateStats] = useState<any>(1);
  const [startDate, setStartDate] = useState<any>(urlStartDate);
  const [endDate, setEndDate] = useState<any>(urlEndDate);
  const [months, setMonths] = useState<any>([]);
  const [employee, setEmployee] = useState<any>();
  const [employeesList, setEmployeesList] = useState<any>([]);
  const [tableHeight, setTableHeight] = useState<any>(150);

  const refSerarchBar = useRef(null)

  const { data: options } = useOptions("presences", "GET");

  function getDateColor(in_out: string, info: string, work_permit?: string) {
    if (work_permit !== null && work_permit !== undefined && work_permit !== "") {
      return "orange";
    }

    if (info === null) {
      return "green";
    }
    if (in_out === "in") {
      if (info.includes("MAN_IN")) {
        return "yellow";
      }
      if (info.includes("AUT_IN")) {
        return "grey";
      }
      return "green";
    }
    else {
      if (info.includes("MAN_OUT")) {
        return "yellow";
      }
      if (info.includes("AUT_OUT")) {
        return "grey";
      }
      return "green";
    }
  }


  function loadEmpoyessList() {
    setLoadingEmplyees(true)
    client(`employees/get_all_employees`, {
      method: "GET",
      data: {
      }
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let employeesTemp = []
          for (let e in response) {
            employeesTemp.push({
              id: response[e].id,
              name: response[e].name,
              surname: response[e].surname,
              label: response[e].id + ": " + response[e].name + " " + response[e].surname
            })
          }
          setEmployeesList(employeesTemp)
          setLoadingEmplyees(false)
          setEmployee(urlEmployee)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoadingEmplyees(false)
      });
  }


  function reloadList() {

    setLoadingData(true)
    client(`./presences/get_employee_days/?start_date=${startDate}&end_date=${endDate}&employees=${employee ? employee : ""}`, {
      method: "GET",
    })
      .then((response) => {
        console.log(response)
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let tempStats = {
            sum_presences: 0,
            sum_minutes: 0,
            sel_sum_presences: 0,
            sel_sum_minutes: 0,
          };
          let tempPresenceDays = []
          for (let p_e in response) {
            tempPresenceDays.push(p_e)
            for (let p in response[p_e]) {
              if (response[p_e][p].hour) {
                tempStats.sum_presences += 1
                tempStats.sum_minutes += response[p_e][p].hour.split(":")[0] * 60 + response[p_e][p].hour.split(":")[1] * 1;
              }
            }
          }
          setStats(tempStats)
          setPresencesData(response)
          setPresencesDays(tempPresenceDays)
          setLoadingData(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoadingData(false)
      });
  }

  useMemo(() => {
    loadEmpoyessList()
    reloadList()
  }, [])

  useEffect(() => {
    if (refSerarchBar !== null && refSerarchBar.current !== null && refSerarchBar!.current!['clientHeight'] !== null)
      try {
        setTableHeight(refSerarchBar!.current!['clientHeight'] + 50)
      } catch (e) {
      }
  }, [
    refSerarchBar.current
  ])

  useEffect(() => {
    let tempStats = stats;
    tempStats.sel_sum_presences = 0;
    tempStats.sel_sum_minutes = 0;
    for (let p_e in presencesData) {
      for (let p in presencesData[p_e]) {
        if (selectedData.includes(p_e) && presencesData[p_e][p].hour) {
          tempStats.sel_sum_presences += 1
          tempStats.sel_sum_minutes += presencesData[p_e][p].hour.split(":")[0] * 60 + presencesData[p_e][p].hour.split(":")[1] * 1;
        }
      }
    }
    setStats(tempStats)
  }, [updateStats])

  useEffect(() => {
    if (months.length > 0) {
      setStartDate(new Date(new Date(new Date(startDate).setDate(1)).setMonth(months[0] - 1)).toISOString().substring(0, 11) + "02:00")
      setEndDate(new Date(new Date(new Date(endDate).setDate(1)).setMonth(months[months.length - 1])).toISOString().substring(0, 11) + "02:00")
    }

  }, [months])

  return (
    <>
      <div style={{
        display: (loadingData || loadingEmplyees) ? "block" : "none",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 999,
      }}>
        <Typography style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "2em",
        }}>
          Loading...
        </Typography>
      </div>



      <div onKeyDown={(e) => {
        if (e.key === "Enter") {
          reloadList()
        }
      }}>
        {employeesList &&
          <Grid container
            ref={refSerarchBar}
            style={{
              padding: "10px",
              marginRight: "30px",
              width: "100%"
            }}>
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}

              id="start_date"
              label="Da"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
            />
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}
              id="end_date"
              label="A"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              multiple
              id="months"
              options={[
                { id: 1, label: 'Gennaio' },
                { id: 2, label: 'Febbraio' },
                { id: 3, label: 'Marzo' },
                { id: 4, label: 'Aprile' },
                { id: 5, label: 'Maggio' },
                { id: 6, label: 'Giugno' },
                { id: 7, label: 'Luglio' },
                { id: 8, label: 'Agosto' },
                { id: 9, label: 'Settembre' },
                { id: 10, label: 'Ottobre' },
                { id: 11, label: 'Novembre' },
                { id: 12, label: 'Dicembre' },
              ]}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let tempMonths = []
                for (let e in newValue) {
                  tempMonths.push(newValue[e].id)
                }
                tempMonths = tempMonths.sort(function (a, b) { return a - b })
                setMonths(tempMonths)
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona mesi"
                />
              )}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              id="tags-outlined"
              options={employeesList}
              getOptionLabel={(option: any) => option.label}
              onChange={(_, v) => setEmployee(v?.id)}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona dipendente"
                />
              )}
            />
            <Button
              style={
                {
                  height: "55px",
                  width: "100px",
                  marginRight: "10px"
                }
              }
              onClick={() => {
                reloadList()
              }}
              label=""
              color="primary"
              variant="contained"
            >
              <Typography>Filtra</Typography>
            </Button>
          </Grid>
        }

        <Grid container
        >
          <Grid item container xs={2}>
            <Typography variant="h6" style={{ marginLeft: "10px" }} >
              {
                employee ? `${employeesList.find((e: any) => e.id == employee).label}` : ""
              }
            </Typography>
          </Grid>
          <Grid item container xs={5}>
            <Grid item xs={3}>
              <StatCard
                label={`Totali`}
                icon={<></>}
                subtitle="Totali pagina"
                backgroundColor="lightblue"
              />
            </Grid>
            <Grid item xs={3}>
              <StatCard
                label={`N°: ${stats.sum_presences}`}
                icon={<ConfirmationNumberIcon />}
                subtitle="Numero turni"
                backgroundColor="lightblue"
              />
            </Grid>
            <Grid item xs={3}>
              <StatCard
                label={`${Math.floor(stats.sum_minutes / 60)}:${("00" + Math.floor(stats.sum_minutes % 60)).slice(-2)}`}
                icon={<AlarmAddIcon />}
                subtitle="Totale ore turni"
                backgroundColor="lightblue"
              />
            </Grid>
            <Grid item xs={3}>
              <StatCard
                label={`${Math.floor(stats.sum_minutes / stats.sum_presences / 60)}:${("00" + Math.floor(stats.sum_minutes / stats.sum_presences % 60)).slice(-2)}`}
                icon={<AvTimerIcon />}
                subtitle="Media ore turni"
                backgroundColor="lightblue"
              />
            </Grid>
          </Grid>
          <Grid item container xs={5}>
            <Grid item xs={3}>
              <StatCard
                label={`Selezionati`}
                icon={<></>}
                subtitle="Totali selezionati"
                backgroundColor="lightcoral"
              />
            </Grid>
            <Grid item xs={3}>
              <StatCard
                label={`N°: ${stats.sel_sum_presences}`}
                icon={<ConfirmationNumberIcon />}
                subtitle="Numero turni selezionati"
                backgroundColor="lightcoral"
              />
            </Grid>
            <Grid item xs={3}>
              <StatCard
                label={`${Math.floor(stats.sel_sum_minutes / 60)}:${("00" + Math.floor(stats.sel_sum_minutes % 60)).slice(-2)}`}
                icon={<AlarmAddIcon />}
                subtitle="Totale ore turni selezionati"
                backgroundColor="lightcoral"
              />
            </Grid>
            <Grid item xs={3}>
              <StatCard
                label={stats.sel_sum_minutes && stats.sel_sum_presences ? `${Math.floor(stats.sel_sum_minutes / stats.sel_sum_presences / 60)}:${("00" + Math.floor(stats.sel_sum_minutes / stats.sel_sum_presences % 60)).slice(-2)}` : "0:00"}
                icon={<AvTimerIcon />}
                subtitle="Media ore turni selezionati"
                backgroundColor="lightcoral"
              />
            </Grid>
          </Grid>
        </Grid>


        <div style={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          height: "calc(100vh - " + tableHeight + "px)",
          width: "100%",
        }}>
          <Table className={classes.root}>
            <TableHead style={{
              position: "sticky",
              top: 0,
              boxShadow: "1px 1px 1px grey",
              borderRight: "1px solid black",
              zIndex: 999,
            }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Giorno</TableCell>
                <TableCell>Data Rif</TableCell>
                <TableCell>Data</TableCell>
                <TableCell></TableCell>
                <TableCell>Ingresso</TableCell>
                <TableCell>Uscita</TableCell>
                <TableCell></TableCell>
                <TableCell>Ore</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Info</TableCell>
                <TableCell>Note In</TableCell>
                <TableCell>Note Out</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {presencesDays.map((day: any) => {
                return (
                  <TableRow key={day} style={{
                    borderTop: new Date(`${day}`).getDate() == 1 ? "4px solid red" : "0px",
                    borderBottom: new Date(`${day}`).getDay() == 0 ? "1.5px solid black" : "0px",
                    backgroundColor:
                      new Date(day).getDay() == 0 ? "rgba(255, 0, 0, 0.5)" :
                        new Date(day).getDay() == 6 ? "rgba(255, 0, 0, 0.2)" : ""
                  }}>
                    <TableCell>
                      <Checkbox
                        onChange={(e) => {
                          e.stopPropagation()
                          if (e.target.checked) {
                            let tempSelectedData = selectedData;
                            tempSelectedData.push(day)
                            setSelectedData(tempSelectedData)
                          }
                          else {
                            let tempSelectedData = selectedData;
                            tempSelectedData = tempSelectedData.filter((e: any) => e !== day)
                            setSelectedData(tempSelectedData)
                          }

                          setUpdateStats(updateStats + 1)

                        }}
                      ></Checkbox>
                    </TableCell>
                    <TableCell style={{
                    }}>
                      {
                        <DateField style={{ fontSize: "1.2em", textTransform: "uppercase" }}
                          record={{
                            id: day,
                            date: day
                          }} source="date" options={{
                            weekday: 'long',
                          }} />
                      }
                    </TableCell>
                    <TableCell style={{
                    }}>
                      {
                        <DateField style={{ fontSize: "1.2em", textTransform: "uppercase" }}
                          record={{
                            id: day,
                            date: day
                          }} source="date" options={{
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          }} />
                      }
                    </TableCell>
                    <TableCell style={{
                    }}>
                      {
                        presencesData[day] && presencesData[day].map((row: any) => (
                          <div style={{
                            maxHeight: subRowHeight + "px",
                            minHeight: subRowHeight + "px",
                          }}>
                            <Typography variant="body2" style={{ color: "black", fontSize: "1.2em", textTransform: "uppercase" }}>
                              {row["date_in"] !== null ? format(new Date(row["date_in"]), "dd/MM") : "--:--:--"}
                            </Typography>
                          </div>
                        ))
                      }
                    </TableCell>
                    <TableCell>
                      {
                        presencesData[day] && presencesData[day].map((row: any) => (
                          <div style={{
                            maxHeight: subRowHeight + "px",
                            minHeight: subRowHeight + "px",
                          }}>
                            <div style={{
                              borderRadius: "50%",
                              border: "0.5px solid black",
                              width: "20px", height: "20px",
                              backgroundColor: getDateColor("in", row["info"], row["work_permit"]),
                              opacity: 0.8
                            }}>

                            </div>
                          </div>
                        ))
                      }
                    </TableCell>


                    <TableCell>
                      {
                        presencesData[day] && presencesData[day].map((row: any) => (
                          <div style={{
                            maxHeight: subRowHeight + "px",
                            minHeight: subRowHeight + "px",
                          }}>
                            <Typography variant="body2" style={{ color: "black", fontSize: "1.2em", textTransform: "uppercase" }}>
                              {row["date_in"] !== null ? format(new Date(row["date_in"]), "HH:mm:SS") : "--:--:--"}
                            </Typography>
                          </div>
                        ))
                      }
                    </TableCell>

                    <TableCell>
                      {
                        presencesData[day] && presencesData[day].map((row: any) => {
                          const date_out = row["date_out"];
                          if (date_out) {

                            const date_out_day = new Date(format(new Date(row["date_out"]), "yyyy-MM-dd"))
                            const date_in_day = new Date(format(new Date(row["date_in"]), "yyyy-MM-dd"))

                            const diff_day = (date_out_day.getTime() - date_in_day.getTime()) / (1000 * 60 * 60 * 24)

                            return (<div style={{
                              maxHeight: subRowHeight + "px",
                              minHeight: subRowHeight + "px",
                            }}>

                              <Typography variant="body2" style={{ color: "black", fontSize: "1.2em", textTransform: "uppercase" }} >
                                {
                                  format(new Date(date_out), "HH:mm:SS")
                                }
                                {
                                  Math.floor(Math.abs(diff_day)) == 0 ? "" : " + " + Math.abs(diff_day) + " gg"
                                }
                              </Typography>

                            </div>)
                          }
                          return (
                            <div style={{
                              maxHeight: subRowHeight + "px",
                              minHeight: subRowHeight + "px",
                            }}></div>
                          );
                        })
                      }
                    </TableCell>


                    <TableCell>
                      {
                        presencesData[day] && presencesData[day].map((row: any) => {
                          const date_out = row["date_out"];
                          if (date_out) {
                            return (<div style={{
                              maxHeight: subRowHeight + "px",
                              minHeight: subRowHeight + "px",
                            }}>

                              <div style={{
                                borderRadius: "50%",
                                border: "0.5px solid black",
                                width: "20px", height: "20px",
                                backgroundColor: getDateColor("out", row["info"], row["work_permit"]),
                                opacity: 0.8
                              }}></div>

                            </div>)
                          }
                          return (
                            <div style={{
                              maxHeight: subRowHeight + "px",
                              minHeight: subRowHeight + "px",
                            }}></div>
                          );
                        })
                      }
                    </TableCell>


                    <TableCell>
                      {
                        presencesData[day] && presencesData[day].map((row: any) => {
                          const date_out = row["date_out"];
                          if (date_out) {
                            return (<div style={{
                              maxHeight: subRowHeight + "px",
                              minHeight: subRowHeight + "px",
                            }}>

                              <Typography variant="body2" style={{ color: "black", fontSize: "1.2em", textTransform: "uppercase" }} >
                                {row["hour"]}
                              </Typography>

                            </div>)
                          }
                          return (
                            <div style={{
                              maxHeight: subRowHeight + "px",
                              minHeight: subRowHeight + "px",
                            }}></div>
                          );
                        })
                      }
                    </TableCell>

                    <TableCell>
                      {
                        presencesData[day] && presencesData[day].map((row: any) => (
                          <div style={{
                            maxHeight: subRowHeight + "px",
                            minHeight: subRowHeight + "px",
                          }}>

                            <Link target="_blank" style={{ color: "black", fontSize: "1.2em", textTransform: "uppercase" }} to={`/presences/${row["id"]}/`} >
                              {row["id"]}
                            </Link>

                          </div>
                        ))
                      }
                    </TableCell>

                    <TableCell>
                      {
                        presencesData[day] && presencesData[day] && presencesData[day].map((row: any) => (
                          <div style={{
                            maxHeight: subRowHeight + "px",
                            minHeight: subRowHeight + "px",
                          }}>

                            <Typography variant="body2" style={{ color: "black", fontSize: "1.2em", textTransform: "uppercase" }} >
                              {row["info_complete"] + row["work_permit"]}
                            </Typography>

                          </div>
                        ))
                      }
                    </TableCell>

                    <TableCell>
                      {
                        presencesData[day] && presencesData[day] && presencesData[day].map((row: any) => (
                          <div style={{
                            maxHeight: subRowHeight + "px",
                            minHeight: subRowHeight + "px",
                          }}>

                            <Typography variant="body2" style={{ color: "black", fontSize: "1em", textTransform: "uppercase" }} >
                              {row["notes_in"]}
                            </Typography>

                          </div>
                        ))
                      }
                    </TableCell>

                    <TableCell>
                      {
                        presencesData[day] && presencesData[day] && presencesData[day].map((row: any) => (
                          <div style={{
                            maxHeight: subRowHeight + "px",
                            minHeight: subRowHeight + "px",
                          }}>

                            <Typography variant="body2" style={{ color: "black", fontSize: "1em", textTransform: "uppercase" }} >
                              {row["notes_out"]}
                            </Typography>

                          </div>
                        ))
                      }
                    </TableCell>

                  </TableRow>)
              }
              )
              }
            </TableBody>
          </Table>

        </div>
      </div >
    </>
  );
};
