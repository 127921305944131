import { Button, ButtonProps, useNotify, useRecordContext, useRefresh } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";
import { useState } from "react";
import { CircularProgress, Typography, Dialog } from "@material-ui/core";

export const UndoSplitAndDeleteButton = (props: ButtonProps) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`${URL}/routes/${record.id}/undo_split/`, {
        method: "GET",
      });
    } catch (err: any) {
      if (err.response.data) {
        try {
          if ("detail" in err.response.data) {
            let s = "" + err.response.data.detail
            notify(s, "error");
            setLoading(false);
            return;
          }
        } catch (error) {
          notify("Error", "error");
          setLoading(false);
          return;
        }
        let errorDict: Record<string, string> = {};
        Object.keys(err.response.data).forEach((key) => {
          errorDict[key] = err.response.data[key].join(". ");
        });
        setLoading(false);
        return errorDict;
      }
      else {
        notify("ra.message.error", "error");
        setLoading(false);
        return;
      }
    }

    setLoading(false);
    notify("Tratte ripristinata e giro eliminato", "success");
    refresh();

  };

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == "backdropClick")
      setOpenDialog(false);;
  }

  return (
    <>
      <Button {...props} disabled={loading} title="Elimina e ripristina tratte" onClick={() => setOpenDialog(true)}>
        {loading ? <CircularProgress size={18} thickness={2} /> : <DeleteIcon />}
      </Button>
      <Dialog open={openDialog} fullWidth maxWidth={'md'}
        onClose={handleClose}>
        <h2>Vuoi confermare l'operazione?</h2>

        <Button onClick={() => setOpenDialog(false)}><Typography>Annulla</Typography></Button>
        <Button onClick={(e) => handleSubmit(e)}><Typography>Conferma</Typography></Button>
      </Dialog>
    </>
  );
};
